import React from "react";
import Layout from "../../components/layout";
import Insurance from "../../components/views/industries/insurance";

const InsurancePage = () => {
  return (
    <Layout>
      <Insurance />
    </Layout>
  );
};

export default InsurancePage;
